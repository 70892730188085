
/**
 *
 */

+function($){

  page.initRecaptcha = function() {
    $('[data-provide~="recaptcha"]').each(function() {

      var options = {
        sitekey: page.defaults.reCaptchaSiteKey
      }

      options = $.extend(options, page.getDataOptions($(this)));

      if (options.enable) {
        options.callback = function() {
          $(options.enable).removeAttr('disabled');
        }

        options['expired-callback'] = function() {
          $(options.enable).attr('disabled', 'true');
        }
      }

      grecaptcha.render( $(this)[0], options);
    });
  }

  page.initRecaptcha3 = function() {
    grecaptcha.ready(function () {
      grecaptcha.execute(page.defaults.reCaptchaV3SiteKey, { action: 'contact' }).then(function (token) {
        $('[data-form="mailer"]').each(function(){
          $(this).prepend('<input type="hidden" name="g-recaptcha-v3-token" value="' + token + '">');
        });
      });
    });
  }



  window.recaptchaLoadCallback = function() {
    page.initRecaptcha();
  }

  window.recaptcha3LoadCallback = function() {
    page.initRecaptcha3();
  }


}(jQuery);

